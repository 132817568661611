import VueI18n from "vue-i18n";
import Vue from "vue";

import store from './store';

// this is used to convert internet.nl messages to the console log. From there you can move them to a translation file.
// import interpolate_translations from "@/locales/merge_wsm_inl";

import nl from "./locales/nl.json";
import en from "./locales/en.json";
import fr from "./locales/fr.json";
import de from "./locales/de.json";


// todo: lazy loading.
Vue.use(VueI18n);

const messages = {
  nl,
  en,
  fr,
  de,
};

function determineLocale() {
  let language = store.state.locale;

  if (language && !Object.keys(messages).includes(language)) {
    language = null;
  }

  // ['en-US'] -> ['en'] -> filter
  language ??= (navigator.languages || [navigator.language])
    .map(language => language.split(/[-_]/)[0])
    .filter(language => store.state.config.app.supported_locales.includes(language))
    .find(language => Object.keys(messages).includes(language))
  ;

  language ??= 'en';

  return language;
}

const i18n = new VueI18n({
  locale: determineLocale(),
  fallbackLocale: 'en',
  // this is needed to silence warnings when usin lazy loading?
  // TODO: find out if this is really needed, there should be none to little missing translations.
  silentFallbackWarn: false,
  // it's requformatDistanceired this is called messages.
  messages,
  sharedMessages: {}
});

/** Needed for Vuex supported_locales changes */
export function refreshLocale() {
  i18n.locale = determineLocale();
}

export default i18n
