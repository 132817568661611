// Note that there is a little hate going on with multiple vue instances.
// See here: https://github.com/LinusBorg/portal-vue/issues/201#issuecomment-484452281
// This explains why there are some extra definitions in the bundler thingies.
import Vue from 'vue'
import VueRouter from 'vue-router'

import App from './App'
import i18n, {refreshLocale} from './i18n';
import router from './router';
import store from './store';
import http from './httpclient';
import LandingPageMixin from "@/components/LandingPageMixin";

// Load the config when the application starts. This is one of the earliest requests, this 'god' object contains
// all kinds of app customizations and settings. It might be a bit large, but this is really what makes the app
// tailored towards end users.
// Timeout after 10 seconds. If the config is not fetched by then the user might nog wait any longer.
// TODO: ideally we should show a loading page after 5-10 seconds if the config takes to long, that way
// we might increase the wait time for the user a little.

let data_url = '/data/config/';
let timeout = 10*1000;
if(store.state.admin){
  data_url = '/manage/data/config/';
  // give user some more time to enter password, also /manage/data urls are slow because no caching
  timeout = 60*1000;
}

http.get(data_url, {timeout}).then(data => {
  if (!(data && data.data && Object.keys(data.data).length != 0)){
    throw new Error("Config is empty!");
  }

  // store config
  store.commit('change', {config: data.data})

  // add any custom countries and layers now the config is loaded
  store.state.custom_countries_and_layers.forEach(t_cl => {
    console.log(`Adding custom layer: ${t_cl.country} - ${t_cl.layer}`)
    add_custom_country_and_layer(store, t_cl.country, t_cl.layer)
  })

  // Support custom style hacks via the admin panel. It should only be used for some
  // easy and straightforward styling rules. When it becomes large/too complex then make your own style.
  // Inject custom CSS as early as possible:
  // re-painting is slow
  if ('app' in data.data && 'custom_css' in data.data.app && data.data.app.custom_css) {
    inject_custom_styles(data.data.app.custom_css)
    overwrite_favicons(data.data.app.favicon)
  }

}).catch((error) => {
  console.error(`Could not retrieve config. Using default minimal config. Error: ${error}`)
  // show error page when loading failed, promping user to reload and give email address to report issues
  Vue.prototype.$loadError = true;
}).finally(()=>{
  new Vue({
    i18n,
    store,
    router,
    mixins: [LandingPageMixin],
    mounted() {
      this.supportLandingArguments();


      // https://stackoverflow.com/questions/901115/how-can-i-get-query-string-values-in-javascript
      // somehow the $route.query object is empty at this point of the application. So i guess we're working around it.
      // add a custom layer via the get parameters, so people don't have to fill in the state form.
      // this is also not possible to add before the finally, while the foreach from store does work
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      if (params.custom_country && params.custom_layer) {
        console.log(`Adding custom layer: ${params.custom_country}/${params.custom_layer}`)
        // this.add_custom_country_and_layer(store, params.custom_country, params.custom_layer)
        this.add_custom_country_and_layer(store, params.custom_country, params.custom_layer)

        // add this to the preview layers as well..
        let things =  this.$store.state.custom_countries_and_layers;
        // don't add it twice:
        let found = false;
        things.forEach(t_cl => {
          if (t_cl.country === params.custom_country && t_cl.layer === params.custom_layer) {
            found = true;
          }
        })
        if (!found) {
          things.push({country: params.custom_country, layer: params.custom_layer})
          this.$store.commit('change', {custom_countries_and_layers: things});
        }

        // if you are on a page that requires country and layer, rewrite the url and then try again
        // http://localhost:8080/report/?custom_country=NL&custom_layer=test
        // http://localhost:8080/map/?custom_country=NL&custom_layer=test
        // for now just go the reports page, that is what people want to see...
        // this doesn't work as the whole issue is that $route is empty.
        let to = "reports-with-country_and_layer"

        // let redirects = {
        //   "report": "reports-with-country_and_layer",
        //   "map": "map-with-country_and_layer",
        //   "charts": "charts_with_country_and_layer",
        // }
        // console.log(this.$route)
        // if (Object.keys(redirects).includes(this.$route.name)) {
        //   to = redirects[this.$route.name]
        // }

        this.$router.push(
          {
            name: to,
            params: {country: params.custom_country, layer: params.custom_layer},
          }
        );

      }

    },

    watch: {
      '$store.state.config.project.name'() {
        if (!document.title.includes(store.state.config.project.name))
          document.title = `${store.state.config.project.name} - ${document.title}`
      },
      '$store.state.config.app.supported_locales'() {
        refreshLocale();
      },
    },
    render: h => h(App),
  }).$mount('#app')
})



import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import {format, formatDistanceToNow, parseISO, differenceInDays} from 'date-fns'
import {enGB, nl, fr, de} from 'date-fns/locale'
import ContentBlock from './components/ContentBlock'
import vSelect from "vue-select";
import AutoRefresh from './components/AutoRefresh'
import LoadingSpinner from './components/LoadingSpinner'
import server_response from './components/ServerResponse'
import VueMatomo from 'vue-matomo'


import './assets/css/styles.scss';
import VueScrollTo from 'vue-scrollto'

Vue.component('content-block', ContentBlock);
Vue.use(VueRouter);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import VueSocialSharing from 'vue-social-sharing'

Vue.use(VueSocialSharing);

// start google maps for the management interface, the api key is needed and there is no way to just just add
// it inside a component. And we'll have to move away from google anyway because paying does not mean owning there.
// at least only install it when the user is admin...
import GmapVue from 'gmap-vue'
if(store.state.admin) {
  Vue.use(GmapVue, {
    load: {
      key: "AIzaSyAP8f5d_YksICEXzpUxXS3B9nGRGgQpiCE",
      libraries: 'places', // This is required if you use the Autocomplete plugin
      region: 'NL',
      language: 'NL',
    },
    installComponents: true
  })
}



Vue.component("v-select", vSelect);


Vue.component('AutoRefresh', AutoRefresh)
Vue.component('LoadingSpinner', LoadingSpinner)
Vue.component('server-response', server_response)
// Vue.component('v-select', VueSelect.VueSelect);

Vue.config.productionTip = false;

Vue.use(VueScrollTo)

// https://www.npmjs.com/package/vue-matomo
Vue.use(VueMatomo, {
  // Configure your matomo server and site by providing
  host: 'https://matomo.internetcleanup.foundation',
  siteId: 1,

  // Changes the default .js and .php endpoint's filename
  // Default: 'matomo'
  trackerFileName: 'matomo',

  // Overrides the autogenerated tracker endpoint entirely
  // Default: undefined
  // trackerUrl: 'https://example.com/whatever/endpoint/you/have',

  // Overrides the autogenerated tracker script path entirely
  // Default: undefined
  // trackerScriptUrl: 'https://example.com/whatever/script/path/you/have',

  // Enables automatically registering pageviews on the router
  router: router,

  // Enables link tracking on regular links. Note that this won't
  // work for routing links (ie. internal Vue router links)
  // Default: true
  enableLinkTracking: false,

  // Require consent before sending tracking information to matomo
  // Default: false
  requireConsent: false,

  // Whether to track the initial page view
  // Default: true
  trackInitialView: true,

  // Run Matomo without cookies
  // Default: false
  disableCookies: false,

  // Require consent before creating matomo session cookie
  // Default: false
  requireCookieConsent: false,

  // Enable the heartbeat timer (https://developer.matomo.org/guides/tracking-javascript-guide#accurately-measure-the-time-spent-on-each-page)
  // Default: false
  enableHeartBeatTimer: true,

  // Set the heartbeat timer interval
  // Default: 15
  heartBeatTimerInterval: 15,

  // Whether or not to log debug information
  // Default: false
  debug: false,

  // UserID passed to Matomo (see https://developer.matomo.org/guides/tracking-javascript-guide#user-id)
  // Default: undefined
  userId: undefined,

  // Share the tracking cookie across subdomains (see https://developer.matomo.org/guides/tracking-javascript-guide#measuring-domains-andor-sub-domains)
  // Default: undefined, example '*.example.com'
  cookieDomain: undefined,

  // Tell Matomo the website domain so that clicks on these domains are not tracked as 'Outlinks'
  // Default: undefined, example: '*.example.com'
  domains: undefined,

  // A list of pre-initialization actions that run before matomo is loaded
  // Default: []
  // Example: [
  //   ['API_method_name', parameter_list],
  //   ['setCustomVariable','1','VisitorType','Member'],
  //   ['appendToTrackingUrl', 'new_visit=1'],
  //   etc.
  // ]
  preInitActions: [],

  // A function to determine whether to track an interaction as a site search
  // instead of as a page view. If not a function, all interactions will be
  // tracked as page views. Receives the new route as an argument, and
  // returns either an object of keyword, category (optional) and resultsCount
  // (optional) to track as a site search, or a falsey value to track as a page
  // view.
  // Default: false, i.e. track all interactions as page views
  // Example: (to) => {
  //   if (to.query.q && to.name === 'search') {
  //     return { keyword: to.query.q, category: to.params.category }
  //   } else {
  //    return null
  //   }
  // }
  trackSiteSearch: false,

  // Set this to include crossorigin attribute on the matomo script import
  // Default: undefined, possible values : 'anonymous', 'use-credentials'
  crossOrigin: undefined,
});


// support for week numbers in javascript
// https://stackoverflow.com/questions/7765767/show-week-number-with-javascript
Date.prototype.getWeek = function () {
  let onejan = new Date(this.getFullYear(), 0, 1);
  return Math.ceil((((this - onejan) / 86400000) + onejan.getDay() + 1) / 7);
};

// support for an intuitive timestamp in weeks...
Date.prototype.humanTimeStamp = function () {
  return this.getFullYear() + " " + i18n.t("app.menu.select-week.today") + " " + this.getWeek();
};


// duplicated as this is also called outside the application scope on init.
// does not yet support counties
function add_custom_country_and_layer(store, new_country, new_layer){

    if (!store.state.config.country_and_layers) {
      store.state.config.country_and_layers = {}
    }

    // see if new_country is already present in country_and_layers, if not, add it:
    if (store.state.config.country_and_layers[new_country] === undefined) {
      store.state.config.country_and_layers[new_country] = {
        code: new_country,
        name: new_country,
        flag: `/static/flags/${new_country.toLowerCase()}.gif`,
        layers: [new_layer]
      }
    }

  if (store.state.config.country_and_layers[new_country].layers.indexOf(new_layer) === -1) {
    store.state.config.country_and_layers[new_country].layers.push(new_layer);
  }
}

Vue.mixin(
  {
    methods: {

    login_plaza_image_link(panel) {
      return `/login_plaza_logos/${this.login_plaza_nicename(panel.portal_name).toLowerCase().replace(/ /g, '-')}.png`
    },
    login_plaza_nicename(name){
      const to_replace = ['Login Panel', ' Admin', 'and Desktop Connections - Web Access', 'Exposed Docs',
        'CMS Detection', 'Dasboard Exposure', 'Login Detected', 'exposed docs', 'Panel', 'Welcome Page - Tech Detect',
        'CMS Documentation', 'Login -  Detect', 'CRM  Login', 'UEM Airwatch'
      ]

      for (let i = 0; i < to_replace.length; i++) {
        name = name.replace(to_replace[i], '')
      }

      return name.trim();
    },

      niceify(text) {
        text = text.replace(/-/g, ' ');
        text = text.replace(/_/g, ' ');
        return text.charAt(0).toUpperCase() + text.slice(1);
      },

      // duplicated outside of app scope
      add_custom_country_and_layer(store, new_country, new_layer){
        console.log(new_country)

        if (!store.state.config.country_and_layers) {
          store.state.config.country_and_layers = {}
        }

        // see if new_country is already present in country_and_layers, if not, add it:
        if (store.state.config.country_and_layers[new_country] === undefined) {
          store.state.config.country_and_layers[new_country] = {
            code: new_country,
            name: new_country,
            flag: `/static/flags/${new_country.toLowerCase()}.gif`,
            layers: [new_layer]
          }
        }

        if (store.state.config.country_and_layers[new_country].layers.indexOf(new_layer) === -1) {
          store.state.config.country_and_layers[new_country].layers.push(new_layer);
        }
      },

      remove_custom_country_and_layer(store, new_country, new_layer){
        if (store.state.config.country_and_layers[new_country].layers.indexOf(new_layer) !== -1) {
          // remove the layer from the list

          let index = store.state.config.country_and_layers[new_country].layers.indexOf(new_layer);
          store.state.config.country_and_layers[new_country].layers.splice(index, 1);
        }
      },

      set_and_navigate_to_report: function (organization_id, organization_name, country, layer) {
        this.$router.push({name: 'report_with_country_and_layer_and_id', params:{
                  layer:layer, country:country, report_id:organization_id}})
      },

      humanize_datetime: function (date) {
        return format(parseISO(date), 'PPPPpppp', {locale: this.dateLocales[this.$i18n.locale]});
      },

      days_ago(date) {
        return differenceInDays(new Date(), parseISO(date))
      },

      humanize_date: function (date) {
        if (!date) {
          return ""
        }

        return format(parseISO(date), 'PPPP', {locale: this.dateLocales[this.$i18n.locale]});
      },
      humanize_relative_date: function (date) {
        if (!date) {
          return ""
        }


        return formatDistanceToNow(parseISO(date), {addSuffix: true, locale: this.dateLocales[this.$i18n.locale]})
      },

      format_large_number(number) {
        return number.toLocaleString(this.$i18n.locale)
      },
      layers_from_country(country) {
        return this.$store.state.config.country_and_layers[country].layers;
      },
      retrieve_and_cache_products() {
        // don't retrieve twice...
        if (this.$store.state.retrieved_technologies) {
          return
        }
        this.$store.state.retrieved_technologies = true;

        http.get(`/data/login_plaza/products/`).then(data => {
          if (data.data !== undefined) {
            this.$store.state.technologies = data.data;
          }
        })
      },
      retrieve_and_cache_cookie_indicators() {
        // don't retrieve twice...
        if (this.$store.state.retrieved_cookie_indicators) {
          return
        }
        this.$store.state.retrieved_cookie_indicators = true;

        http.get(`/data/cookies/indicators/`).then(data => {
          if (data.data !== undefined) {
            this.$store.state.cookie_indicators = data.data;
          }
        })
      },
      translate_issue_name: function (string) {
        // give priority to friendly translations
        let translation = this.$i18n.t(`metric.${string}.title`);
        if (!translation.startsWith("metric")) {
          return translation
        }

        // fallback to standard translations
        if (string.startsWith('internet_nl')) {
          return this.$i18n.t(string + "_label");
        }
        return this.$i18n.t(string);
      },
      categories(issue_name) {
        return this.categories_list(issue_name).join(", ")
      },
      categories_list(issue_name) {
        let translated_categories = []

        if (!this.$store.state.config.scan_types[issue_name])
          return []

        let technology = this.$i18n.t(`metric.${issue_name}.technology`)
        if (!technology.startsWith("metric")) {
          translated_categories.push(technology)
        }

        this.$store.state.config.scan_types[issue_name].category.forEach(category_name => {
          translated_categories.push(this.$t(`category.category_${category_name}`))
        })

        return translated_categories
      },

      navigateToMap: function (country, layer) {
        this.$router.push(`/map/${country}/${layer}`);
      },
      perc: function (amount, total, digits, percentage_sign) {
        if (digits === undefined) {
          digits = 2;
        }
        if (percentage_sign === undefined) {
          percentage_sign = "%";
        }

        return (!amount || !total) ? "0" + percentage_sign : this.roundTo(amount / total * 100, digits) + percentage_sign;
      },
      percentage(amount, total, digits, percentage_sign) {
        return this.perc(amount, total, digits, percentage_sign)
      },
      // https://stackoverflow.com/questions/15762768/javascript-math-round-to-two-decimal-places
      roundTo: function (n, digits) {
        if (digits === undefined) {
          digits = 0;
        }

        let multiplicator = Math.pow(10, digits);
        n = parseFloat((n * multiplicator).toFixed(11));
        let test = (Math.round(n) / multiplicator);
        return +(test.toFixed(digits));
      },
    },
    computed: {
      dateLocales: function () {
        return {nl: nl, en: enGB, de: de, fr: fr}
      },

      reported_organization: () => {
        return store.state.reported_organization;
      },
      layers: () => {
        return store.state.layers;
      },
      organizations: () => {
        return store.state.organizations;
      },
      ordered_visible_issue_names: function () {
        if (this.$store.state.config.scan_types === undefined)
          return [];

        // $store.state.config.show.issues[issue['name']]
        let visible_issues = [];
        Object.keys(this.$store.state.config.scan_types).forEach((key) => {
          if (this.$store.state.config.show.issues[key]) {
            visible_issues.push(this.$store.state.config.scan_types[key])
          }
        })

        return visible_issues;
      },
      countries() {
        return Object.keys(this.$store.state.config.country_and_layers)
      },
      axis_for_issue_graphs() {
        let axis = [];
        if (this.$store.state.config.app.relevant_for_ui.good)
          axis.push("ok")
        if (this.$store.state.config.app.relevant_for_ui.low)
          axis.push("low")
        if (this.$store.state.config.app.relevant_for_ui.medium)
          axis.push("medium")
        if (this.$store.state.config.app.relevant_for_ui.high)
          axis.push("high")
        return axis
      },
      axis_for_org_and_url_graphs() {
        let axis = [];
        if (this.$store.state.config.app.relevant_for_ui.good)
          axis.push("good")
        // there are no lows here...
        // if (this.$store.state.config.app.relevant_for_ui.low)
        //  axis.push("low")
        if (this.$store.state.config.app.relevant_for_ui.medium)
          axis.push("medium")
        if (this.$store.state.config.app.relevant_for_ui.high)
          axis.push("high")
        return axis
      },
      all_categories() {
        // translated category name which points to a list of issues in this category
        let categories = {}

        let issue_names = Object.keys(this.$store.state.config.scan_types)

        issue_names.forEach(issue_name => {
          this.$store.state.config.scan_types[issue_name].category.forEach(category_name => {
            let translated_category = this.$t(`category.category_${category_name}`)
            if (categories[translated_category] === undefined) {
              categories[translated_category] = []
            }
            categories[translated_category].push(issue_name)
          })
        })

        return categories
      },
    },
    watch: {
      state: function () {
        this.load();
      }
    }

  }
);

function inject_custom_styles(css_data) {
  /* https://stackoverflow.com/questions/23050001/insert-multiple-css-rules-into-a-stylesheet */
  let head = document.getElementsByTagName('head')[0];
  let link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'data:text/css,' + escape(css_data);  // IE needs this escaped
  head.appendChild(link);
  // document.getElementById('custom-styles').innerHTML = css_data;
}

function overwrite_favicons(directory) {

  const favicons = {
    "favicon-apple-touch-icon": "apple-touch-icon.png",
    "favicon-icon-small": "favicon-32x32.png",
    "favicon-icon-large": "favicon-16x16.png",
    "favicon-manifest": "site.webmanifest",
    "favicon-mask-icon": "safari-pinned-tab.svg"
  }

  for (const [key, value] of Object.entries(favicons)) {
    document.getElementById(key).href = `/favicons/${directory}/${value}`;
  }

}


/**
 * Use google maps geolocation API to make autocompletion easy.
 * */
Vue.use(GmapVue, {
  load: {
    key: store.state.config.google_maps_api_key,  // , AIzaSyAP8f5d_YksICEXzpUxXS3B9nGRGgQpiCE
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)
    region: 'NL',
    language: 'NL',

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'gmap-vue/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

// retrieving colors for the graphs is done via this function, which allows the use of css variable names
// inside the graphs at startup.


Vue.prototype.$http = http;

Vue.prototype.$loadError = false;

// support parameters in comply or explain email templates:
String.prototype.format = function () {
  // store arguments in an array
  let args = arguments;
  // use replace to iterate over the string
  // select the match and check if the related argument is present
  // if yes, replace the match with the argument
  return this.replace(/{([0-9]+)}/g, function (match, index) {
    // check if the argument is present
    return typeof args[index] == 'undefined' ? match : args[index];
  });
};

String.prototype.supplant = function (o) {
    return this.replace(/{([^{}]*)}/g,
        function (a, b) {
            var r = o[b];
            return typeof r === 'string' || typeof r === 'number' ? r : a;
        }
    );
};
